import React from 'react'
import Wrapper from './Styles'
import { Container } from 'reactstrap'
import { LoadingSVG } from '../../../Components/UI/'

import { useReport } from './useReport'
import TournamentMenu from '../TournamentMenu'

/**
 *
 * @param {*} param0
 * @returns
 */
const TournamentReport = props => {
  const { match, isMobile, loggedUser } = props
  const { loading, message, tournament, races, havePermissions } = useReport(
    loggedUser
  )

  if (loading) {
    return <LoadingSVG message={message} />
  }
  return (
    <Wrapper>
      <Container>
        <>
          <TournamentMenu
            isMobile={isMobile}
            tournament={tournament}
            havePermissions={havePermissions}
            match={match}
            user={loggedUser}
          />
          <div style={{ maxWidth: '100%', margin: 'auto' }}>
            {races.length > 0 && (
              <table className='table table-striped table-hover'>
                <thead>
                  <tr>
                    <th>Edición</th>
                    <th>Raza</th>
                  </tr>
                </thead>
                <tbody>
                  {races.map((race, key) => {
                    return (
                      <tr className='ListElement' key={key}>
                        <td>{race.edition}</td>
                        <td>
                          {race.total > 0 && <button>{race.total}</button>}{' '}
                          {race.name}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </>
      </Container>
    </Wrapper>
  )
}

export default TournamentReport
