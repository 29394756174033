// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const getRegions = countryId => {
  const requestId = 'getRegions'
  getToken()
  return dispatch => {
    const query = `
      query allRegions {
        allRegions(paginate: 1000, filter: { countryId: ${countryId} }, orderBy: sortOrder_ASC) {
          fullName
          id
        }
      }

    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.allRegions) {
          return response.data.data.allRegions
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getRegions
