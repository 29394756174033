// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default id => {
  const requestId = 'FindGame'
  return async dispatch => {
    getToken()
    const query = `
      query Game {
        Game(id: ${id}) {
          id
          name
          description
          sortOrder
          urlImage
          image
          Seasons {
            id
            name
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const data = response.data.data.Game
        if (data) {
          return {
            Game: data,
            status: {
              success: true
            }
          }
        } else {
          return {
            status: {
              success: false
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
