// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const DeleteTournamentOrganizer = id => {
  return async dispatch => {
    getToken()
    const query = `
      mutation deleteTournamentOrganizer {
        deleteTournamentOrganizer (id: ${id}) {
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query })
      .then(response => {
        if (response.data.errors) {
          return {
            status: {
              name: 'No se pudo eliminar el organizador del torneo.',
              success: false
            }
          }
        } else {
          const { deleteTournamentOrganizer } = response.data.data
          return {
            status: {
              name: `Se eliminó exitosamente del torneo`,
              success: true
            },
            deletedId: deleteTournamentOrganizer.id
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default DeleteTournamentOrganizer
