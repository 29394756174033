// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getAlert = () => {
  const requestId = 'getAlert'
  return dispatch => {
    getToken()

    const query = `
      query allAlerts {
        allAlerts(filter: { active: 1 }, paginate: 1) {
          text
          status
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const { allAlerts } = response.data.data
        if (allAlerts) {
          dispatch({
            type: 'SET_ALERT',
            payload: allAlerts[0]
          })
        }
        return allAlerts
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getAlert
