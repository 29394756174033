import { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getTournamentInfo } from '../../../Redux/Actions'
import { razasEdiciones } from './constants'

/**
 * Hook Tournaments.
 * @returns
 */
export const useReport = loggedUser => {
  const { tournamentId } = useParams()
  const [tournament, setTournament] = useState()
  const [message] = useState('Buscando torneos')
  const [loading, setLoading] = useState(true)
  const [canFind, setCanFind] = useState(true)
  const [havePermissions, setHavePermissions] = useState(false)
  const [races, setRaces] = useState(new Map())
  // const [filters, setFilters] = useState(initFilters)
  // const [states, setStates] = useState(initStates)

  const onInit = useCallback(async () => {
    const tournamentResolve = await getTournamentInfo({
      tournamentId: tournamentId,
      listPlayers: true
    })()

    if (tournamentResolve.status.success) {
      const formatRaces = tournamentResolve.Game.RaceGames.sort(
        (a, b) => a.sortOrder - b.sortOrder
      ).reduce((acc, race) => {
        acc.set(race.raceId, {
          edition:
            razasEdiciones[race.raceId] &&
            razasEdiciones[race.raceId].has(tournamentResolve.Game.id)
              ? razasEdiciones[race.raceId].get(tournamentResolve.Game.id)
              : '',
          name: race.Race.name,
          total: 0
        })
        return acc
      }, new Map())
      tournamentResolve.TournamentPlayers.forEach(element => {
        if (formatRaces.has(element.raceId)) {
          formatRaces.get(element.raceId).total++
        }
      })

      // Valida si el usuario tiene permisos para ver el torneo.
      const havePermissions =
        loggedUser.user && loggedUser.user.Store
          ? tournamentResolve.storeId === loggedUser.user.Store.id ||
            loggedUser.isAdmin
          : false

      setHavePermissions(havePermissions)
      setRaces(Array.from(formatRaces.values()))
      setTournament(tournamentResolve)
    } else {
      window.location = '/'
    }
    setLoading(false)
  }, [])

  const handleSubmit = async () => {
    setCanFind(true)
    // return search()
  }
  /** Al iniciar el hook busca los datos iniciales. */
  useEffect(() => {
    onInit()
  }, [])

  return {
    tournament,
    loading,
    message,
    handleSubmit,
    races,
    canFind,
    havePermissions
  }
}
