// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
class TournamentMenu extends React.Component {
  state = {
    urls: [],
    loading: true,
    dropdownOpen: false
  }
  componentDidMount () {
    const { match, tournament, havePermissions, user } = this.props

    /** Base URLS. */
    const urls = [
      {
        href: tournament.Store.urlView,
        text: (
          <>
            <i className='fa fa-home' /> Tienda
          </>
        ),
        active: false
      },
      {
        href: tournament.urlView,
        text: (
          <>
            <i className='fa fa-info-circle' /> Info Torneo
          </>
        ),
        active: match.path.includes('detail') || match.path.includes('edit')
      }
    ]

    /** Si tiene permisos ve la lista de participantes. */
    if (havePermissions) {
      urls.push({
        href: tournament.urlAddPlayers,
        text: (
          <>
            <i className='fa fa-users' /> Participantes
          </>
        ),
        active: match.path.includes('players')
      })
    }

    const tournamentStarted = [3, 4, 5, 6].includes(
      tournament.TournamentStatus.id
    )
    /** Si está en estado 3,4,5,6 ve las rondas. */
    if (tournamentStarted) {
      urls.push({
        href: tournament.urlRounds,
        text: (
          <>
            <i className='fa fa-list' /> Rondas Resultados
          </>
        ),
        active:
          match.path.includes('rounds') ||
          match.path.includes('round') ||
          match.path === '/tournament/standing/:roundId/:slug'
      })
    }

    /** Si está en estado 3,4,5,6 y si es admin o tiene permisos. */
    if (
      tournamentStarted &&
      [4, 6].includes(tournament.gameId) &&
      ((user && user.isAdmin) || havePermissions)
    ) {
      urls.push({
        href: `/tournament/report/${tournament.id}/report`,
        text: (
          <>
            <i className='fa fa-list' /> Reporte razas
          </>
        ),
        active:
          match.path.includes('report') ||
          match.path === '/tournament/report/:roundId/:slug'
      })
    }
    this.setState({
      urls,
      loading: false
    })
  }

  render () {
    const { urls, loading } = this.state
    if (loading) return null
    return (
      <Wrapper>
        <ul
          className={classnames({
            flex: this.props.isMobile
          })}>
          <>
            {urls
              .filter(u => u)
              .map((url, key) => {
                return (
                  <li key={key}>
                    <Link to={url.href} className={url.active ? 'active' : ''}>
                      {url.text}
                    </Link>
                  </li>
                )
              })}
          </>
        </ul>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentMenu.propTypes = {
  tournament: PropTypes.object.isRequired
}

export default TournamentMenu

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournament__menu' })`
  background-color: #333;
  margin-top: 40px;
  width: 100%;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li {
      float: left;
      a {
        display: block;
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        &:hover:not(.active) {
          background-color: #111;
        }
      }
      .active {
        background-color: #4caf50;
      }
    }
  }
  @media (max-width: 425px) {
    ul li {
      width: 100%;
    }
  }
`
