// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const requestId = 'getGames'

export default ({ findSeasons = true } = {}) => {
  getToken()
  return dispatch => {
    const seasons = findSeasons
      ? `Seasons { id name isActive rankingUrl gameId }`
      : ''
    const query = `
      query allGames {
        allGames(orderBy: sortOrder_ASC) {
          name
          description
          urlImage
          urlUpdate
          image
          id
          urlActiveSeason
          totalTournaments
          ${seasons}
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200) {
          return response.data.data.allGames
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
