// @External Dependencies
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Dropdown from 'reactstrap/lib/Dropdown'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import DropdownItem from 'reactstrap/lib/DropdownItem'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// @Dependencies
import { Icon } from '../../Components/UI/Icons'
import { UserLogout, getAlert } from '../../Redux/Actions'
import variables from '../UI/variables'
import ValidateMail from './ValidateMail'
import AlertContent from './AlertContent'
import logoTOR from '../../../assets/img/tor_logo.png'

// @Component
export class Header extends Component {
  state = {
    dropdownOpen: false,
    showAlert: false,
    menuOpen: false,
    alertRoutes: ['/home', '/ranking', '/stores', '/tournaments'],
    lastUrl: ''
  }

  async componentDidMount () {
    // await this.props.getAlert()
    // await this.setAlert()
  }
  async componentDidUpdate () {
    // await this.setAlert()
  }

  async setAlert () {
    const { match } = this.props
    if (this.props.ui && Object.keys(this.props.ui.alert).length) {
      if (
        this.state.alertRoutes.includes(match.path) &&
        !this.state.showAlert
      ) {
        this.setState({
          showAlert: true,
          lastUrl: match.path
        })
      } else if (this.state.showAlert && match.path !== this.state.lastUrl) {
        this.setState({ showAlert: false })
      }
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }))
  }

  clickMenu = e => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  onLogout = () => {
    const { history } = this.props
    window.localStorage.removeItem('persist:myl')
    this.props.UserLogout()
    history.push('/login')
  }

  render () {
    const { loggedUser, isMobile, t } = this.props
    const { alert } = this.props.ui
    const { dropdownOpen, showAlert, menuOpen } = this.state
    const logged = loggedUser.user && Object.keys(loggedUser.user).length > 0
    return (
      <Fragment>
        <Wrapper className='Header'>
          <Container fluid={isMobile}>
            <Row>
              <Col md='12'>
                <WrapperNav>
                  <div className='nav-wrapper'>
                    {isMobile && (
                      <React.Fragment>
                        <div
                          className='nav-menu burger'
                          onClick={this.clickMenu}>
                          <span>
                            <i className='fa fa-bars' />
                          </span>
                        </div>
                        <div className='nav-menu logo'>
                          <Link to='/ranking' className='logo'>
                            <img src={logoTOR} alt='Logo TOR' />
                          </Link>
                        </div>
                      </React.Fragment>
                    )}
                    {!isMobile && (
                      <React.Fragment>
                        <div className='nav-menu-left flex'>
                          <Link to='/ranking'>Ranking</Link>
                          <Link to='/tournaments'>Torneos</Link>
                          <Link to='/stores'>Tiendas</Link>
                          {/* {!logged && (
                            <a
                              href='https://myl.cl'
                              target='_blank'
                              rel='noopener noreferrer'>
                              MyL.cl
                            </a>
                          )} */}
                          <Link to='/ranking' className='logo'>
                            <img src={logoTOR} alt='Logo TOR' />
                          </Link>
                          <a
                            href='https://tor.myl.cl/cartas'
                            target='_blank'
                            rel='noopener noreferrer'>
                            Cartas
                          </a>
                          <a
                            href='https://tor.myl.cl/mazos'
                            target='_blank'
                            rel='noopener noreferrer'>
                            Mazos
                          </a>
                          {/* {logged && (
                            <a
                              href='https://myl.cl'
                              target='_blank'
                              rel='noopener noreferrer'>
                              MyL.cl
                            </a>
                          )} */}

                          {logged ? (
                            <div className='Header__topbar flex'>
                              <div className='Header__user flex'>
                                <Dropdown
                                  isOpen={dropdownOpen}
                                  toggle={this.toggle}>
                                  <DropdownToggle caret>Perfil</DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>{`${loggedUser.user.name} (${
                                      loggedUser.user.code
                                    })`}</DropdownItem>
                                    <DropdownItem>
                                      <Link to='/profile'>
                                        <div>
                                          <i className='fa fa-user' />{' '}
                                          {t('Ver mi Perfil')}
                                        </div>
                                        <Icon
                                          name='arrow'
                                          className='arrow-icon'
                                          stroke='#c6c6c6'
                                        />
                                      </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <a
                                        href={`/user/tournaments/${
                                          loggedUser.user.code
                                        }`}>
                                        <div>
                                          <i className='fa fa-trophy' /> Mis
                                          torneos jugados
                                        </div>
                                        <Icon
                                          name='arrow'
                                          className='arrow-icon'
                                          stroke='#c6c6c6'
                                        />
                                      </a>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <a
                                        href={`/user/tournaments/${
                                          loggedUser.user.code
                                        }/organizer`}>
                                        <div>
                                          <i className='fa fa-gamepad' /> Mis
                                          torneos organizados
                                        </div>
                                        <Icon
                                          name='arrow'
                                          className='arrow-icon'
                                          stroke='#c6c6c6'
                                        />
                                      </a>
                                    </DropdownItem>
                                    {loggedUser.user.emailValid > 0 &&
                                      loggedUser.user.Store && (
                                        <DropdownItem>
                                          <Link to='/store'>
                                            <div>
                                              <i className='fa fa-shopping-cart' />{' '}
                                              {!loggedUser.user.Store
                                                ? t('Inscribir Tienda')
                                                : t('Ver Tienda')}
                                            </div>
                                            <Icon
                                              name='arrow'
                                              className='arrow-icon'
                                              stroke='#c6c6c6'
                                            />
                                          </Link>
                                        </DropdownItem>
                                      )}

                                    <DropdownItem>
                                      <Link to='/tournament-types'>
                                        <div>
                                          <i className='fa fa-cogs' /> Tipos
                                          Torneo
                                        </div>
                                        <Icon
                                          name='arrow'
                                          className='arrow-icon'
                                          stroke='#c6c6c6'
                                        />
                                      </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Link to='/formats'>
                                        <div>
                                          <i className='fa fa-cogs' /> Formatos
                                          Juego
                                        </div>
                                        <Icon
                                          name='arrow'
                                          className='arrow-icon'
                                          stroke='#c6c6c6'
                                        />
                                      </Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Link to='/games'>
                                        <div>
                                          <i className='fa fa-cogs' /> Juegos
                                        </div>
                                        <Icon
                                          name='arrow'
                                          className='arrow-icon'
                                          stroke='#c6c6c6'
                                        />
                                      </Link>
                                    </DropdownItem>
                                    {loggedUser.isAdmin && (
                                      <React.Fragment>
                                        <DropdownItem>
                                          <Link to='/admin/seasons'>
                                            <div>
                                              <i className='fa fa-trophy' />{' '}
                                              Temporadas
                                            </div>
                                            <Icon
                                              name='arrow'
                                              className='arrow-icon'
                                              stroke='#c6c6c6'
                                            />
                                          </Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                          <Link to='/users'>
                                            <div>
                                              <i className='fa fa-users' />{' '}
                                              Usuarios
                                            </div>
                                            <Icon
                                              name='arrow'
                                              className='arrow-icon'
                                              stroke='#c6c6c6'
                                            />
                                          </Link>
                                        </DropdownItem>
                                      </React.Fragment>
                                    )}
                                    {logged && (
                                      <DropdownItem
                                        onClick={this.onLogout}
                                        className='logout'>
                                        {t('Desconectarme')}
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment>
                              <Link to='/login' className='login'>
                                <i className='fa fa-sign-in' /> Ingresar
                              </Link>
                              <Link to='/register' className='login register'>
                                <i className='fa fa-user-plus' /> REGISTRO
                              </Link>
                            </React.Fragment>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </WrapperNav>
                {menuOpen && (
                  <WrapperNavMobile>
                    <ul
                      id='nav-mobile'
                      className='side-nav fixed hide-on-large-only'>
                      <li className='logo' onClick={this.clickMenu}>
                        <Link to='/ranking' className='logo'>
                          <h3>
                            <img src={logoTOR} alt='Logo TOR' />
                          </h3>
                        </Link>
                      </li>
                      <li onClick={this.clickMenu}>
                        <Link to='/ranking'>Ranking</Link>
                      </li>
                      <li onClick={this.clickMenu}>
                        <Link to='/tournaments'>Torneos</Link>
                      </li>
                      <li onClick={this.clickMenu}>
                        <Link to='/stores'>Tiendas</Link>
                      </li>
                      <li>
                        <div className='divider' />
                      </li>
                      <li onClick={this.clickMenu}>
                        <a
                          href='https://tor.myl.cl/cartas'
                          target='_blank'
                          rel='noopener noreferrer'>
                          Cartas
                        </a>
                      </li>
                      <li onClick={this.clickMenu}>
                        <a
                          href='https://tor.myl.cl/mazos'
                          target='_blank'
                          rel='noopener noreferrer'>
                          Mazos
                        </a>
                      </li>
                      <li onClick={this.clickMenu}>
                        <a
                          href='https://myl.cl'
                          target='_blank'
                          rel='noopener noreferrer'>
                          MyL.cl
                        </a>
                      </li>
                      {logged ? (
                        <React.Fragment>
                          <li>
                            <div className='divider' />
                          </li>
                          <li onClick={this.clickMenu}>
                            <span>
                              {`${loggedUser.user.fullName} (${
                                loggedUser.user.code
                              })`}
                            </span>
                          </li>
                          <li onClick={this.clickMenu}>
                            <Link to='/profile'>
                              <i className='fa fa-user' /> {t('Ver mi Perfil')}
                            </Link>
                          </li>
                          <li onClick={this.clickMenu}>
                            <Link
                              to={`/user/tournaments/${loggedUser.user.code}`}>
                              <i className='fa fa-trophy' />{' '}
                              {t('Mis Torneos jugados')}
                            </Link>
                          </li>
                          <li onClick={this.clickMenu}>
                            <Link
                              to={`/user/tournaments/${
                                loggedUser.user.code
                              }/organizer`}>
                              <i className='fa fa-gamepad' />{' '}
                              {t('Mis Torneos organizados')}
                            </Link>
                          </li>
                          <li onClick={this.clickMenu}>
                            <Link to='/store'>
                              <i className='fa fa-shopping-cart' />{' '}
                              {!loggedUser.user.Store
                                ? 'Inscribir Tienda'
                                : 'Ver Tienda'}
                            </Link>
                          </li>
                          <li onClick={this.clickMenu}>
                            <Link to='/tournament-types'>
                              <i className='fa fa-cogs' /> Tipos de Torneos
                            </Link>
                          </li>
                          <li onClick={this.clickMenu}>
                            <Link to='/formats'>
                              <i className='fa fa-cogs' /> Formatos Juego
                            </Link>
                          </li>
                          {loggedUser.isAdmin && (
                            <React.Fragment>
                              <li onClick={this.clickMenu}>
                                <Link to='/admin/seasons'>
                                  <i className='fa fa-trophy' /> Temporadas
                                </Link>
                              </li>
                              <li onClick={this.clickMenu}>
                                <Link to='/users'>
                                  <i className='fa fa-users' /> Usuarios
                                </Link>
                              </li>
                            </React.Fragment>
                          )}
                          <li onClick={this.clickMenu}>
                            <a href='/profile' onClick={this.onLogout}>
                              <i className='fa fa-user' /> {t('Desconectarme')}
                            </a>
                          </li>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <li onClick={this.clickMenu}>
                            <Link to='/login' className='login'>
                              <i className='fa fa-sign-in' /> Ingresar
                            </Link>
                          </li>
                          <li onClick={this.clickMenu}>
                            <Link to='/register' className='login register'>
                              <i className='fa fa-user-plus' /> Registrate
                            </Link>
                          </li>
                        </React.Fragment>
                      )}
                    </ul>
                  </WrapperNavMobile>
                )}
              </Col>
            </Row>
          </Container>
        </Wrapper>
        {logged && !loggedUser.user.emailValid && (
          <Container>
            <Row>
              <Col md={12}>
                <ValidateMail email={loggedUser.user.email} />
              </Col>
            </Row>
          </Container>
        )}
        {showAlert && alert && (
          <Container>
            <Row>
              <Col md={12}>
                <AlertContent alert={alert} />
              </Col>
            </Row>
          </Container>
        )}
      </Fragment>
    )
  }
}

// @Proptypes
Header.propTypes = {
  loggedUser: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

/*
  @Store Connection: connect
  @Export Component
*/
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserLogout,
      getAlert
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(Header)

// @Styles
const WrapperNavMobile = styled.nav`
  .side-nav {
    position: fixed;
    width: 300px;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    background-color: rgba(44, 57, 64, 0.98);
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
    &.fixed {
      transform: translateX(0px);
    }

    .divider {
      margin: 8px 0 0 0;
      height: 1px;
      overflow: hidden;
      background-color: #e0e0e0;
    }

    li {
      float: none;
      line-height: 48px;
      list-style-type: none;
      a,
      span {
        color: rgba(255, 255, 255, 0.87);
        display: block;
        font-size: 14px;
        font-weight: 500;
        height: 48px;
        line-height: 48px;
        padding: 0 32px;
        overflow: hidden;
        &.login {
          text-align: center;
          background-color: #ff000080;
          &.register {
            background-color: #fff;
            color: #000;
          }
        }
      }
      &.logo {
        margin: 0px 0px 80px 0px;
        h3 {
          font-size: 2.92rem;
          line-height: 110%;
          margin: 1.46rem 0 1.168rem 0;
          text-align: center;
        }
        a {
          overflow: unset;
        }
      }
    }
  }
`

const WrapperNav = styled.nav`
  background-color: rgba(26, 26, 26, 0.8);
  height: 69px;
  margin-top: 36px;
  .brand-logo {
    margin-top: -36px;
    &.promobrand {
      margin-top: -20px;
      margin-left: 6px;
      img {
        width: 180px;
      }
    }
  }
  .logo {
    img {
      margin-top: -40px;
      z-index: 99;
      position: relative;
    }
  }
  .nav-wrapper {
    border-left: none;
    border-right: none;
    display: flex;
  }

  .nav-menu-left,
  .nav-menu-right {
    /* display: inline-block; */
    height: 71px;
  }

  .nav-menu-left {
    width: 100%;
    float: left;
    text-align: right;
  }

  .nav-menu-right {
    width: 60%;
    float: right;
    text-align: left;
  }

  .nav-menu-left a,
  .nav-menu-right a {
    line-height: 2rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin: 11px 0px 0px;
    display: inline-block;
    padding: 5px 25px;
    color: white;
  }
  .nav-menu-left a:hover,
  .nav-menu-left a:hover,
  .nav-menu-left a.login,
  .Header__topbar:hover {
    background-color: rgba(255, 0, 0, 0.5);
    border-radius: 3px;
    text-shadow: none;
    &.register {
      background-color: #fff;
      color: #000;
    }
  }
  .nav-menu-left a.logo:hover {
    background-color: transparent;
  }
  .Header__topbar {
    /* display: inline-block; */
    padding: 5px 25px;
    margin: 11px 0px 0px;
  }
  .nav-menu {
    &.burger {
      color: white;
      font-size: 30px;
      margin: 0px 20px;
    }
    &.logo {
      width: 100%;
      text-align: center;
      &:hover {
        background-color: initial;
      }
    }
  }
`
const Wrapper = styled.header`
  background: #1a1a1a;
  padding: 5px 0;
  &.fixed {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
  }

  .Header__user {
    figure {
      margin: 10px 0;
    }

    .bell-icon,
    .comment-icon {
      cursor: pointer;
      margin-right: 30px;

      &:hover {
        path,
        g {
          fill: ${variables.setColors.primary};
        }
      }
    }

    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle,
    .btn-secondary.focus,
    .btn-secondary:focus {
      background: none;
      border: none;
      box-shadow: none;
    }

    .dropdown {
      &.show:before {
        border-color: transparent transparent #ffffff transparent;
        border-style: solid;
        border-width: 0 10px 6px 10px;
        bottom: -7px;
        content: '';
        height: 0;
        left: 50%;
        margin-left: -10px;
        position: absolute;
        z-index: 99999;
      }
      button {
        background: none;
        border: 0;
        padding: 0;

        &:after {
          display: none;
        }
      }

      &-menu {
        left: inherit !important;
        padding: 0;
        right: -10px !important;
        top: 28px !important;
        transform: none !important;
        width: 290px;

        .dropdown-item {
          border-bottom: 1px solid ${variables.setColors.whiteFour};
          padding: 20px;
          text-transform: uppercase;

          &:hover {
            cursor: pointer;
          }

          &:active,
          &:focus {
            color: currentColor;
            outline: 0;
          }

          &:first-child {
            font-weight: 300;
            padding: 13px 20px;
            &:hover {
              cursor: default;
            }
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10) {
            &:not(.logout) {
              align-items: center;
              color: ${variables.setColors.brownGreyTwo};
              display: flex;
              font-size: 12px;
              padding: 0;

              a div svg {
                margin-right: 10px;
              }
            }
          }

          &:last-child {
            background: #f4f4f4;
            font-size: 12px;
            text-align: center;
            padding: 8px 0;
            text-transform: none;
          }

          a {
            align-items: center;
            color: ${variables.setColors.brownGreyTwo};
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            // padding: 13px 20px;
            width: 100%;
            margin: 0px;
            &:hover {
              background-color: #f4f4f4;
            }
          }

          .nucleo-icon {
            margin: 0;
          }
        }
      }
    }
  }

  .ValidateMail {
    color: white;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .Header__user {
      .dropdown-menu {
        right: -5px !important;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    .Header__user {
      figure {
        margin: 7px 0;
      }

      i {
        margin-right: 20px;
      }

      .dropdown-menu {
        top: 44px !important;
      }
    }
  }
`
