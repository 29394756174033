// @External Dependencies
import axios from 'axios'
import jwtDecode from 'jwt-decode'
// @Dependencies
import { store } from '../../Redux/Store'
import { encryptData } from '../../../cryptoData'

const getToken = () => {
  // Agrega a todas las peticiones el app-referer
  const href = window.location.href
  const encrypted = encryptData(href)
  const token = store.getState().loggedUser.token
  let expired = false
  if (token) {
    const tokenDecoded = jwtDecode(token)
    if (!tokenDecoded.newToken) {
      expired = true
    } else {
      const currentTime = new Date().getTime() / 1000
      if (currentTime < tokenDecoded.exp) {
        axios.defaults.headers.common.Authorization = `bearer ${token}`
        axios.defaults.headers['app-referer'] = href
        axios.defaults.headers.common['app-referer'] = href
        axios.defaults.headers.jiatan = encrypted
        axios.defaults.headers.common.jiatan = encrypted
        return tokenDecoded
      } else {
        expired = true
      }
    }
  }

  if (expired) {
    window.sessionStorage.setItem('session', 'expired')
    if (window.localStorage) {
      window.localStorage.removeItem('persist:myl')
    }
    window.location.assign('/users/inactive')
  }

  axios.defaults.headers['app-referer'] = href
  axios.defaults.headers.common['app-referer'] = href
  axios.defaults.headers['jiatan'] = encrypted
  axios.defaults.headers.common['jiatan'] = encrypted
  return null
}

// @Export Component
export default getToken
