// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { isValid, format } from 'date-fns'
import { es } from 'date-fns/locale'
import { bgBackground } from '../../../assets/img/login'
import {
  Jumbotron,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import classnames from 'classnames'

import ReactCountryFlag from 'react-country-flag'
import TournamentList from '../Tournaments/TournamentList'
// @Dependencies
import {
  getTournamentInfo,
  getUser,
  TournamentDelete,
  TournamentFinish,
  TournamentCancel,
  UploadImage
} from '../../Redux/Actions'
import { LoadingSVG, Button, Toggle, Input } from '../../Components/UI'
import { axiosAbort } from '../../Services'
import TournamentMenu from './TournamentMenu'

// @Component
class TournamentDetailView extends Component {
  state = {
    alertContent: '',
    buttonBack: '',
    alertState: 'warning',
    loading: true,
    havePermissions: false,
    tournament: {},
    activeTab: 1,
    modalCancel: false,
    modalDelete: false,
    checkDelete: false,
    coverImage: '',
    isAdmin: false,
    isOrganizer: false
  }

  /**
   * - Al iniciar busca los datos del torneo
   * - Si no tengo permisos para editar el torneo no vemos el menu y administración
   */
  async componentDidMount () {
    await this.search()
  }
  componentWillUnmount () {
    axiosAbort()
  }
  search = async () => {
    const { getTournamentInfo, match } = this.props
    await this.props.getUser()
    const tournament = await getTournamentInfo(match.params)
    if (tournament.status.success) {
      const { loggedUser } = this.props
      const isOrganizer = tournament.TournamentOrganizers.some(organizer => {
        if (loggedUser.user) {
          return organizer.personId === loggedUser.user.id
        }
        return false
      })
      const havePermissions =
        loggedUser.user && loggedUser.user.Store
          ? tournament.Store.id === loggedUser.user.Store.id ||
            loggedUser.isAdmin
          : isOrganizer

      tournament.description = tournament.description.split(`\n`).join('<br />')

      this.setState({
        isOrganizer,
        havePermissions,
        isAdmin: loggedUser.isAdmin,
        tournament,
        loading: false,
        coverImage: tournament.urlImage
      })
    } else {
      const detailError = tournament.status.detail
      this.setState({
        buttonBack: '/tournaments',
        alertContent: `No se pudieron encontrar los datos del torneo.<br /> "${detailError}"`
      })
    }
  }

  async componentDidUpdate (prevProps) {
    const { tournamentId } = this.props.match.params
    const prevTournament = prevProps.match.params.tournamentId

    if (prevTournament !== tournamentId) {
      await this.search()

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  endTournament = async () => {
    const { TournamentFinish } = this.props
    const { tournament } = this.state

    this.setState({
      loading: true,
      alertContent:
        'Estamos reportando los resultados de los jugadores al ranking.'
    })
    const response = await TournamentFinish(tournament.id)
    if (response.status.success) {
      this.setState({
        alertContent: response.status.detail
      })
    } else {
      this.setState({
        alertContent: response.status.detail
      })
    }

    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  cancelTournament = async () => {
    const { TournamentCancel } = this.props
    const { tournament } = this.state

    this.setState({
      loading: true,
      alertContent: 'Estamos cancelando el reporte del torneo al Ranking.'
    })
    const response = await TournamentCancel(tournament.id)
    this.setState({
      alertContent: response.status.detail
    })

    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }
  deleteTournament = async () => {
    const { tournament, checkDelete, isOrganizer } = this.state
    const { loggedUser } = this.props
    if (isOrganizer) {
      this.setState({
        alertContent: 'Organizadores no pueden borrar los torneos',
        loading: true
      })
      setTimeout(() => {
        this.props.history.push(
          `/user/tournaments/${loggedUser.user.code}/organizer`
        )
      }, 3000)
    } else if (checkDelete) {
      this.setState({ loading: true })
      const { status } = await this.props.TournamentDelete(tournament.id)
      if (status.success) {
        this.setState({
          alertContent: status.detail
        })
        setTimeout(() => {
          this.props.history.push('/tournaments')
        }, 3000)
      } else {
        this.setState({ loading: false })
      }
    }
  }

  toggle = async tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  togglemodalCancel = () => {
    this.setState({
      modalCancel: !this.state.modalCancel
    })
  }
  togglemodalDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete
    })
  }
  onChangeModalToggle = e => {
    e.preventDefault()
    const target = e.target
    let { checkDelete } = this.state

    if (target.id === 'confirmDelete') {
      checkDelete = !checkDelete
    }

    this.setState({ checkDelete })
  }

  upload = e => {
    const files = e.target.files
    const file = files[0]
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async e => {
        const response = await this.props.UploadImage({
          file: e.target.result,
          name: files[0].name,
          type: 'tournament.image',
          tournamentId: this.state.tournament.id
        })

        if (response.status.success) {
          this.setState({
            coverImage: response.Tournament.urlImage
          })
        }
      }
    } else {
      alert('Error en formato, sólo se permiten JPG o PNG')
    }
  }

  render () {
    const {
      loading,
      havePermissions,
      alertContent,
      tournament,
      buttonBack,
      isAdmin
    } = this.state
    const { t, match, isMobile } = this.props
    if (loading) {
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />
    }

    const address = [
      tournament.Address.address,
      tournament.Address.Region.fullName,
      tournament.Address.Location ? tournament.Address.Location.name : ''
    ]
    const fullAddress = address.join(' ')
    const addressLink = address.map(a => a.replace(' ', '+')).join('+')

    const dateFormatted = isValid(new Date(tournament.startDate))
      ? format(new Date(tournament.startDate), `EEEE dd 'de' MMMM y`, {
          locale: es
        })
      : ''

    /**
     * Puede editar si es que:
     * - Tiene permisos
     * - El estado del torneo está marcado para "editar"
     * - Es admin y no está en estado REPORTADO (6).
     */
    const canEdit =
      havePermissions &&
      (tournament.TournamentStatus.edit === 1 ||
        (isAdmin && tournament.TournamentStatus.id !== 6))

    return (
      <Wrapper>
        <Container>
          <TournamentMenu
            isMobile={isMobile}
            tournament={tournament}
            havePermissions={havePermissions}
            match={match}
            user={this.props.loggedUser}
            t={t}
          />
          <Jumbotron>
            <Col md={12} className='HeaderInfo'>
              <h3>Torneo: "{tournament.name.trim()}"</h3>
              <p className={`lead text-${tournament.TournamentStatus.style}`}>
                <b>{tournament.TournamentStatus.name}: </b>
                {tournament.TournamentStatus.description}
              </p>
            </Col>
            <Col md={12} className={`HeaderInfo ${isMobile ? '' : 'flex'}`}>
              <div className='buttons' style={{ width: '100%' }}>
                {havePermissions &&
                  (tournament.TournamentStatus.id === 1 ||
                  tournament.TournamentStatus.id === 2 ? (
                    <Link
                      className='btn btn-primary'
                      to={tournament.urlAddPlayers}>
                      <i className='fa fa-users' /> {t('Gestión Jugadores')}
                    </Link>
                  ) : (
                    <React.Fragment>
                      <Button
                        state='success'
                        link={tournament.urlRounds}
                        text='Rondas Resultados'
                      />
                      {tournament.TournamentStatus.id === 5 && (
                        <Button
                          text='Reportar torneo'
                          state='warning'
                          onClick={this.endTournament}
                        />
                      )}
                      {tournament.TournamentStatus.id === 6 && isAdmin && (
                        <>
                          <Button
                            text='Cancelar Torneo'
                            state='danger'
                            onClick={() => this.togglemodalCancel()}
                            style={{ width: '150px' }}
                          />
                          {/* Modal REPORTAR TORNEO */}
                          <Modal
                            isOpen={this.state.modalCancel}
                            toggle={() => this.togglemodalCancel()}>
                            <ModalHeader
                              toggle={() => this.togglemodalCancel()}>
                              Gestión de Torneo
                            </ModalHeader>
                            <ModalBody>
                              <p>
                                Al confirmar <b>"Cancelar Torneo"</b> los
                                puntajes ya reportados al ranking se restarán a
                                los jugadores. Luego podrás modificar la última
                                ronda. El torneo volverá a estado "En Proceso".
                              </p>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                state='default'
                                onClick={() => this.togglemodalCancel()}
                                text='Mantener como está'
                              />
                              <Button
                                state='danger'
                                onClick={this.cancelTournament}
                                text={'Cancelar Torneo'}
                              />
                            </ModalFooter>
                          </Modal>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                {canEdit && (
                  <Link className='btn btn-secondary' to={tournament.urlEdit}>
                    <i className='fa fa-edit' /> {t('Editar datos')}
                  </Link>
                )}
                {havePermissions && !this.state.isOrganizer && (
                  <React.Fragment>
                    <span
                      className={`btn btn-danger ${
                        isMobile ? 'btn-block' : ''
                      }`}
                      onClick={() => this.togglemodalDelete()}>
                      <i className='fa fa-trash' />
                      &nbsp; Eliminar torneo
                    </span>
                    <Modal
                      isOpen={this.state.modalDelete}
                      toggle={() => this.togglemodalDelete()}>
                      <ModalHeader toggle={() => this.togglemodalDelete()}>
                        Gestión de Torneo
                      </ModalHeader>
                      <ModalBody>
                        <h2 className='text-danger text-center'>¡CUIDADO!</h2>
                        <p>
                          Al confirmar <b>"Eliminar Torneo"</b> todas las rondas
                          se eliminarán, se perderán posiciones, y todo registro
                          asociado al Torneo. Esta acción es IRREVERSIBLE.
                        </p>
                        <p>
                          Si borras el torneo no podrá ser recuperado. Elimínalo
                          sólo si estás seguro.
                        </p>
                        <Toggle
                          label={
                            'Soy consciente de que al borrar el torneo no podré recuperarlo.'
                          }
                          name={`confirmDelete`}
                          onChange={this.onChangeModalToggle}
                          checked={this.state.checkDelete}
                          size='12'
                          labelSize={6}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          state='default'
                          onClick={() => this.togglemodalDelete()}
                          text='Cancelar'
                        />
                        <Button
                          state='danger'
                          disabled={!this.state.checkDelete}
                          onClick={this.deleteTournament}
                          text={'Eliminar Torneo'}
                        />
                      </ModalFooter>
                    </Modal>
                  </React.Fragment>
                )}
              </div>
            </Col>

            <Nav tabs style={{ marginTop: '20px' }}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === 1
                  })}
                  onClick={() => {
                    this.toggle(1)
                  }}>
                  <i className='fa fa-info-circle' /> Detalles del Torneo{' '}
                </NavLink>
              </NavItem>
              {(tournament.TournamentClasifications.length > 0 ||
                tournament.TournamentRelateds.length) > 0 && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 2
                    })}
                    onClick={() => {
                      this.toggle(2)
                    }}>
                    <i className='fa fa-info-circle' /> Torneos Relacionados
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === 3
                  })}
                  onClick={() => {
                    this.toggle(3)
                  }}>
                  <i className='fa fa-map-pin' /> Tienda y Dirección
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={1}>
                <Card body>
                  {havePermissions && (
                    <Col md={12}>
                      <Input
                        disabled={loading}
                        inputSize={10}
                        label={t('Imagen promocional')}
                        name='photo'
                        placeholder={t('Imagen promocional')}
                        value=''
                        onChange={this.upload}
                        type='file'
                      />
                    </Col>
                  )}
                  <div className='grid'>
                    <Jumbotron>
                      <h5>Descripción</h5>
                      <p>{ReactHtmlParser(tournament.description)}</p>
                    </Jumbotron>
                    <div className='image'>
                      {(this.state.coverImage.length > 0 ||
                        this.state.tournament.TournamentType.urlImage.length >
                          0) && (
                        <img
                          src={
                            this.state.coverImage ||
                            this.state.tournament.TournamentType.urlImage
                          }
                          alt={tournament.name}
                          className='img-thumbnail'
                          style={{ marginBottom: '20px' }}
                        />
                      )}
                    </div>
                  </div>
                  <ul>
                    <li>
                      Categoría de Tienda: <b>{tournament.Store.Level.name}</b>
                      <br />
                      <i>{tournament.Store.Level.description}</i>
                    </li>
                    <li>
                      Modalidad de juego:{' '}
                      <b>
                        {tournament.isPresential
                          ? ReactHtmlParser(
                              '<i class="fa fa-users"></i> Presencial'
                            )
                          : ReactHtmlParser(
                              '<i class="fa fa-camera"></i> Remoto'
                            )}
                      </b>
                    </li>
                    <li>Fecha realización: {dateFormatted}</li>
                    {tournament.eventUrl.length > 0 && (
                      <li>
                        <span>Link Redes Sociales: </span>
                        <a
                          href={tournament.eventUrl}
                          target='_blank'
                          rel='noopener noreferrer'>
                          {tournament.eventUrl}
                        </a>
                      </li>
                    )}
                    <li>
                      Temporada de juego:{' '}
                      <Link to={tournament.Season.rankingUrl}>
                        {tournament.Season.name} --{' '}
                        <i className='fa fa-trophy' />{' '}
                        {tournament.Season.Game.name}
                      </Link>
                    </li>
                    <li>
                      Formato <u>"{tournament.Format.name}"</u>{' '}
                      {tournament.Format.description.length
                        ? `: -- ${tournament.Format.description}`
                        : ''}
                    </li>
                    {tournament.TournamentType && (
                      <li>
                        Tipo Torneo: {tournament.TournamentType.name}
                        <ul>
                          <li>
                            Por cada ronda ganada sumarás{' '}
                            {tournament.TournamentType.winnerPoints *
                              tournament.TournamentType.multiplier}{' '}
                            puntos al ranking
                          </li>
                          <li>
                            Por cada ronda perdida sumarás{' '}
                            {tournament.TournamentType.loserPoints *
                              tournament.TournamentType.multiplier}{' '}
                            puntos al ranking
                          </li>
                          <li>
                            Por cada ronda empatada sumarás{' '}
                            {tournament.TournamentType.drawPoints *
                              tournament.TournamentType.multiplier}{' '}
                            puntos al ranking
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </Card>
              </TabPane>
              <TabPane tabId={2}>
                <Card body>
                  {tournament.TournamentClasifications.length > 0 && (
                    <React.Fragment>
                      <h2 className='text-center'>
                        Este torneo te clasifica directo a los eventos...{' '}
                      </h2>
                      <TournamentList
                        tournaments={tournament.TournamentClasifications.map(
                          c => {
                            return {
                              ...c,
                              ...c.TournamentRelated,
                              Store: tournament.Store,
                              quotaText: `${
                                c.quotas
                              } mejores jugadores del torneo.`
                            }
                          }
                        )}
                        md={6}
                        isMobile={this.props.isMobile}
                        t={t}
                      />
                    </React.Fragment>
                  )}
                  {tournament.TournamentRelateds.length > 0 && (
                    <React.Fragment>
                      <h2 className='text-center'>
                        Puedes clasificar a este torneo ganando cupos en los
                        siguientes eventos:
                      </h2>
                      <TournamentList
                        tournaments={tournament.TournamentRelateds.map(c => {
                          return {
                            ...c,
                            ...c.Tournament,
                            Store: tournament.Store,
                            quotaText: `${c.quotas} directos al ${
                              tournament.name
                            }`
                          }
                        })}
                        md={6}
                        isMobile={this.props.isMobile}
                        t={t}
                      />
                    </React.Fragment>
                  )}
                </Card>
              </TabPane>
              <TabPane tabId={3}>
                <Card body>
                  {tournament.urlImage && (
                    <img
                      src={tournament.urlImage}
                      alt={tournament.name}
                      className='img-thumbnail'
                      style={{ marginBottom: '20px' }}
                    />
                  )}
                  <Table
                    className={`table-bordered table-hover ${
                      this.props.isMobile ? 'table-responsive' : ''
                    }`}>
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          <Link to={tournament.Store.urlView}>
                            <h2 className='text-center'>
                              {tournament.Store.name}
                            </h2>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th scope='row'>Dirección</th>
                        <td colSpan={2}>
                          {
                            <a
                              href={`https://www.google.com/maps/place/${addressLink}`}
                              target='_blank'
                              rel='noopener noreferrer'>
                              {fullAddress}{' '}
                              <ReactCountryFlag
                                code={tournament.Address.Country.iso}
                                svg
                              />{' '}
                            </a>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card>
              </TabPane>
            </TabContent>
          </Jumbotron>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentDetailView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/
const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTournamentInfo,
      TournamentDelete,
      TournamentFinish,
      TournamentCancel,
      UploadImage,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentDetailView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Login' })`
  height: inherit;
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;
  @media screen and (max-width: 1024px) {
    .HeaderInfo {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 426px) {
    .nav-tabs {
      .nav-item {
        width: 100%;
      }
    }
    .tab-pane {
      h1 {
        font-size: 1.7rem;
      }
    }
  }
  .img-thumbnail {
    // max-width: 480px;
  }
`
