// @External Dependencies
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { bgBackground } from '../../../../assets/img/login'
import {
  Jumbotron,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Container
} from 'reactstrap'

import classnames from 'classnames'

import {
  getTournamentInfo,
  getTournamentPlayers,
  getUser,
  UserFind,
  TournamentPlayerRegister,
  TournamentPlayerEdit,
  TournamentRoundStart,
  getStorePlayers
} from '../../../Redux/Actions'
import {
  LoadingSVG,
  Input,
  Select,
  Button,
  Alert,
  Avatar
} from '../../../Components/UI'
import { axiosAbort } from '../../../Services'
import TournamentMenu from '../TournamentMenu'
import TournamentInfo from '../Rounds/TournamentInfo'
import { TournamentOrganizers } from './organizers'

import PlayersPdf from './players_pdf'
import SimpleUserRegister from './simpleRegister'

// @Component
class TournamentPlayersView extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    buttonBack: '',
    search: '',
    searchLocal: '',
    modal: false,
    activeTab: 1,
    addPlayer: {
      message: '',
      search: '',
      personId: 0,
      raceId: 0,
      results: [],
      disabled: false,
      loading: false
    },
    loading: true,
    loadingLocal: true,
    havePermissions: false,
    tournament: {},
    races: [],
    players: [],
    PlayersLocal: [],
    PlayersLocalFilter: [],
    playerEdit: {},
    renderPdf: this.props.match.params.hasOwnProperty('pdf')
  }

  /**
   * - Al iniciar busca los datos del torneo
   * - Si no tengo permisos para editar el torneo no podemos ver la vista
   */
  async componentDidMount () {
    await this.props.getUser()
    const { getTournamentInfo, match } = this.props
    const tournament = await getTournamentInfo({
      ...match.params,
      listPlayers: true
    })
    if (tournament.status.success) {
      const { loggedUser } = this.props
      const havePermissions =
        loggedUser.user && loggedUser.user.Store
          ? tournament.storeId === loggedUser.user.Store.id ||
            loggedUser.isAdmin
          : tournament.TournamentOrganizers.some(organizer => {
              if (loggedUser.user) {
                return organizer.personId === loggedUser.user.id
              }
              return false
            })
      const races = tournament.Game.RaceGames.sort(
        (a, b) => a.sortOrder - b.sortOrder
      ).reduce((acc, raceGame) => {
        const data = {
          id: raceGame.raceId,
          name: raceGame.Race.name
        }
        if (tournament.Format.isFullRacial) {
          acc.push(data)
        }
        return acc
      }, [])
      havePermissions
        ? this.setState({
            havePermissions,
            tournament,
            races,
            loading: false
          })
        : this.setState({
            alertContent: 'No tienes permisos para ver esta sección.',
            buttonBack: tournament.urlView
          })

      const resolvePlayers = await this.props.getTournamentPlayers(
        tournament.id
      )
      if (resolvePlayers && resolvePlayers.status.success) {
        const players = resolvePlayers.Players.map(player => {
          return {
            ...player.Person,
            id: player.id,
            byes: player.byes,
            drop: player.drop,
            points: player.points,
            personId: player.Person.id,
            raceId: player.raceId,
            RoundDrop: player.TournamentRoundDrop,
            clasified: player.Classified ? player.Classified.name : ''
          }
        })
        const { addPlayer } = this.state
        addPlayer.disabled = players.length >= tournament.maxPlayers
        this.setState({
          players,
          addPlayer
        })
      }
    } else {
      this.setState({
        buttonBack: '/tournaments',
        alertContent: `No se pudieron encontrar los datos del torneo.<br /> "${
          tournament.status.detail
        }"`
      })
    }
  }
  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Al buscar un jugador refresca la lista de opciones o agrega directamente.
   */
  handleAddPlayer = async e => {
    e && e.preventDefault()
    const { players, tournament } = this.state
    let { addPlayer } = this.state
    if (addPlayer.search.length > 1) {
      const excludes = players.map(player => {
        return player.personId
      })
      this.setState({
        addPlayer: {
          ...addPlayer,
          loading: true
        },
        alertContent: '',
        alertState: 'warning'
      })
      const findPlayers = await this.props.UserFind(addPlayer.search, excludes)
      if (findPlayers.status.success) {
        /* if (findPlayers.Persons.length === 1) {
          addPlayer.personId = findPlayers.Persons[0].id
          this.setState({ addPlayer })
          this.handleRegisterPlayer(e)
        } else {
        } */
        this.setState({
          addPlayer: {
            message: findPlayers.status.name,
            search: '',
            personId: 0,
            raceId: 0,
            results: findPlayers.Persons.map(p => {
              return {
                id: p.id,
                name: `${p.fullName} (${p.code}) - ${p.email}`
              }
            }),
            disabled: players.length >= tournament.maxPlayers,
            loading: false
          }
        })
      } else {
        this.setState({ loading: false })
      }
    }
  }

  /**
   * Agregado rápido de jugador local al torneo.
   */
  newLocalPlayer = async person => {
    const { addPlayer } = this.state
    addPlayer.personId = person.id
    this.setState({ addPlayer, loadingLocal: true })
    await this.handleRegisterPlayer(null)
  }

  /**
   * Registra a un jugador
   */
  handleRegisterPlayer = async e => {
    e && e.preventDefault()
    const { players } = this.state
    const { TournamentPlayerRegister, match } = this.props
    const data = {
      tournamentId: parseInt(match.params.tournamentId, 10),
      personId: this.state.addPlayer.personId,
      raceId: this.state.addPlayer.raceId
    }
    const register = await TournamentPlayerRegister(data)
    if (register.status.success) {
      players.push({
        id: register.TournamentPlayer.id,
        personId: register.TournamentPlayer.Person.id,
        fullName: register.TournamentPlayer.Person.fullName,
        code: register.TournamentPlayer.Person.code,
        email: register.TournamentPlayer.Person.email,
        emailValid: register.TournamentPlayer.Person.emailValid,
        raceId: register.TournamentPlayer.raceId
      })
      const fullName = register.TournamentPlayer.Person.fullName

      this.setState({
        players,
        addPlayer: {
          message: '',
          search: '',
          personId: 0,
          raceId: 0,
          results: [],
          disabled: false,
          loading: false
        },
        alertContent: `${fullName} fue agregado al torneo.`,
        alertState: 'success',
        tournament: {
          ...this.state.tournament,
          rounds: register.rounds,
          maxPlayers:
            players.length >= this.state.tournament.maxPlayers
              ? players.length
              : this.state.tournament.maxPlayers
        }
      })

      if (this.state.activeTab === 3) {
        this.searchRecurrentPlayers()
      }
    } else {
      this.setState({
        alertContent: `${register.status.name} ${register.status.detail}`,
        alertState: 'danger',
        loadingLocal: false
      })
    }
  }

  /**
   * Reemplaza jugadores (primero los busca) luego los cambia
   */
  handleReplacePlayer = async e => {
    e.preventDefault()
    const { players, tournament } = this.state
    let { addPlayer } = this.state
    if (addPlayer.search.length > 3) {
      const excludes = players.map(player => {
        return player.personId
      })
      this.setState({
        addPlayer: {
          ...addPlayer,
          loading: true
        },
        alertContent: '',
        alertState: 'warning'
      })
      const findPlayers = await this.props.UserFind(addPlayer.search, excludes)
      if (findPlayers.status.success) {
        this.setState({
          addPlayer: {
            message: findPlayers.status.name,
            search: '',
            personId: 0,
            raceId: 0,
            results: findPlayers.Persons.map(p => {
              return {
                id: p.id,
                name: `${p.fullName} (${p.code}) - ${p.email}`
              }
            }),
            disabled: players.length >= tournament.maxPlayers,
            loading: false
          }
        })
      }
    }
  }

  /**
   * Luego de seleccionar el jugador cambiamos el personId de la tabla TournamentPlayer
   */
  handleReplacePlayer4Player = async e => {
    e.preventDefault()
    const { players } = this.state
    const { TournamentPlayerRegister, match } = this.props
    const data = {
      tournamentId: parseInt(match.params.tournamentId, 10),
      personId: this.state.addPlayer.personId
    }
    const register = await TournamentPlayerRegister(data)
    if (register.status.success) {
      players.push({
        id: register.TournamentPlayer.id,
        personId: register.TournamentPlayer.Person.id,
        fullName: register.TournamentPlayer.Person.fullName,
        code: register.TournamentPlayer.Person.code,
        email: register.TournamentPlayer.Person.email,
        emailValid: register.TournamentPlayer.Person.emailValid,
        raceId: register.TournamentPlayer.raceId
      })
      const fullName = register.TournamentPlayer.Person.fullName
      this.setState({
        players,
        addPlayer: {
          message: '',
          search: '',
          personId: 0,
          raceId: 0,
          results: [],
          disabled: false,
          loading: false
        },
        alertContent: `${fullName} fue agregado al torneo.`,
        alertState: 'success',
        tournament: {
          ...this.state.tournament,
          maxPlayers:
            players.length >= this.state.tournament.maxPlayers
              ? players.length
              : this.state.tournament.maxPlayers
        }
      })
    } else {
      this.setState({
        alertContent: register.status.name
      })
    }
  }

  /**
   * Filtra jugadores inscritos
   */
  handleSearchPlayer = async e => {
    e.preventDefault()
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  /**
   * Manipula los cambios en los Jugadores
   */
  updatePlayer = async () => {
    let { loading, players, playerEdit, addPlayer } = this.state
    if (loading) return false

    let alertContent = 'Borrando jugador...'
    const { TournamentPlayerEdit } = this.props
    const data = {
      id: playerEdit.id,
      action: playerEdit.action
    }
    if (playerEdit.action === 'bye') {
      data.bye = playerEdit.bye
      alertContent = 'Agregando ronda libre...'
    }
    if (playerEdit.action === 'change') {
      data.personId = addPlayer.personId
      alertContent = 'Actualizando jugador en el torneo...'
    }

    this.setState({
      loading: true,
      alertContent
    })

    const result = await TournamentPlayerEdit(data)
    if (result.status.success) {
      if (playerEdit.action === 'delete') {
        players = players.filter(p => {
          if (p.id === result.TournamentPlayer.id) {
            return false
          } else {
            return true
          }
        })
        window.location.reload()
      }
      if (playerEdit.action === 'bye') {
        players = players.map(p => {
          if (p.id === result.TournamentPlayer.id) {
            p.byes = result.TournamentPlayer.byes
          }
          return {
            ...p
          }
        })
      }

      if (playerEdit.action === 'change') {
        window.location.reload()
      }
    }
    setTimeout(() => {
      let alert = result.status.name
      if (result.status.detail) {
        alert = `${alert} - ${result.status.detail}`
      }
      this.setState({
        loading,
        players,
        alertContent: alert,
        alertState: result.status.success ? 'success' : 'danger',
        modal: false,
        playerEdit: {}
      })
    }, 500)
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    e.preventDefault()
    const target = e.target
    const { addPlayer } = this.state
    if (target.id === 'addPlayer') {
      addPlayer.search = target.value.trim()
    }
    if (target.id === 'replacePlayer') {
      addPlayer.search = target.value.trim()
    }
    if (target.id === 'personId') {
      addPlayer.personId = parseInt(target.value, 10)
    }
    if (target.id === 'raceId') {
      addPlayer.raceId = parseInt(target.value, 10)
    }
    if (target.id === 'replacePersonId') {
      addPlayer.personId = parseInt(target.value, 10)
    }
    this.setState({
      addPlayer
    })
  }

  /** Cambia la raza del jugador. */
  onChangeRace = async (e, playerId, personId) => {
    e.preventDefault()
    const target = e.target
    let raceId = 0
    if (target.id === 'raceId') {
      raceId = parseInt(target.value, 10)
    }

    let alertContent = 'Cambiando de raza'
    let alertState = 'warning'
    const { TournamentPlayerEdit } = this.props
    const data = {
      id: playerId,
      action: 'race',
      raceId
    }
    if (!raceId) {
      return false
    }
    this.setState({
      loading: true,
      alertContent
    })

    const result = await TournamentPlayerEdit(data)
    if (result.status.success) {
      alertContent = 'Cambio de raza exitoso'
      alertState = 'success'
      const { players } = this.state
      this.setState({
        players: players.reduce((acc, player) => {
          if (player.id === playerId) {
            player.raceId = raceId
          }
          acc.push(player)
          return acc
        }, [])
      })
    } else {
      alertContent = 'Error al cambio de raza'
      alertState = 'warning'
    }

    this.setState({
      loading: false,
      alertContent,
      alertState
    })
  }

  searchRecurrentPlayers = async loading => {
    const data = {
      filter: {
        tournamentId: this.state.tournament.id,
        seasonId: this.state.tournament.seasonId
      }
    }
    this.setState({ loading })
    const PlayersLocal = await this.props.getStorePlayers(data)
    if (PlayersLocal.status.success) {
      setTimeout(() => {
        this.setState({
          PlayersLocal: PlayersLocal.Players,
          PlayersLocalFilter: [],
          loadingLocal: false
        })
      }, 500)
    }
  }
  /**
   * startTournament
   */
  startTournament = async e => {
    e.preventDefault()
    this.setState({
      loading: true,
      alertContent: 'Estamos iniciando la primera ronda'
    })
    const { TournamentRoundStart, history } = this.props
    const result = await TournamentRoundStart({
      tournamentId: this.state.tournament.id
    })
    if (result.status.success) {
      this.setState({
        alertContent: result.status.name
      })
      setTimeout(() => {
        history.push(result.Round.urlView)
      }, 1500)
    } else {
      this.setState({
        alertContent: `${result.status.name} - ${result.status.detail}`
      })

      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 1500)
    }
  }

  toggle = async tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        alertContent: ''
      })

      if (tab === 3) {
        await this.searchRecurrentPlayers(false)
      }
    }
  }

  filterLocals = e => {
    const { PlayersLocal } = this.state

    e.preventDefault()
    const target = e.target
    if (target.id === 'locales') {
      const filter = target.value.toLowerCase()
      const PlayersLocalFilter = PlayersLocal.filter(player => {
        return player.fullName.toLowerCase().includes(filter)
      })
      this.setState({
        PlayersLocalFilter
      })
    }
  }

  render () {
    const {
      alertContent,
      buttonBack,
      loading,
      renderPdf,
      players,
      tournament
    } = this.state
    if (loading) {
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />
    } else {
      return renderPdf ? (
        <PlayersPdf players={players} tournament={tournament} />
      ) : (
        this.renderView()
      )
    }
  }

  renderView () {
    const {
      loading,
      havePermissions,
      alertContent,
      alertState,
      tournament,
      addPlayer,
      players,
      playerEdit,
      races
    } = this.state
    const { t, match } = this.props

    const filterPlayers =
      this.state.PlayersLocalFilter.length > 0
        ? this.state.PlayersLocalFilter
        : this.state.PlayersLocal

    const someBye =
      players.filter(p => {
        return p.byes
      }).length > 0
    const someDrop =
      players.filter(p => {
        return p.drop
      }).length > 0

    return (
      <Wrapper>
        <Container>
          <TournamentMenu
            isMobile={this.props.isMobile}
            tournament={tournament}
            havePermissions={havePermissions}
            match={match}
            user={this.props.loggedUser}
            t={t}
          />
          <TournamentInfo
            tournament={tournament}
            isMobile={this.props.isMobile}
          />
          {[1, 2, 3, 4, 5, 6].includes(tournament.TournamentStatus.id) && (
            <Jumbotron>
              <h1 className='display-5'>
                Gestión{' '}
                {tournament.TournamentStatus.id === 3 ? (
                  <i>
                    <u>TARDIA</u>
                  </i>
                ) : (
                  ''
                )}{' '}
                de participantes
              </h1>
              <p>
                En esta sección podrás agregar jugadores y organizadores al
                torneo.
              </p>
              <p>
                Si un usuario no tiene código de usuario, puedes registrarlo en
                la sección{' '}
                <b
                  onClick={() => {
                    this.toggle(5)
                  }}
                  style={{ cursor: 'pointer' }}>
                  Registrar nuevo jugador
                </b>
              </p>
              {tournament.TournamentStatus.id === 3 && (
                <p className='text-danger'>
                  Al inscribir un jugador de forma tardía, si hay un jugador bye
                  será asociado automáticamente a esa mesa. De lo contrario,
                  será asignado como bye en una nueva mesa.
                </p>
              )}
              {alertContent.length > 0 && (
                <Alert color={alertState}>
                  {ReactHtmlParser(alertContent)}
                </Alert>
              )}

              <React.Fragment>
                <div className='buttons'>
                  {!this.props.isMobile ? (
                    <a
                      href={`${tournament.urlAddPlayers}/pdf`}
                      className='btn btn-primary'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <i className='fa fa-print' /> Imprimir Listado
                    </a>
                  ) : null}
                  {[1, 2, 3].includes(tournament.TournamentStatus.id) && (
                    <Button
                      link={tournament.urlEdit}
                      text='Editar torneo'
                      state='info'
                    />
                  )}
                  {[1, 2].includes(tournament.TournamentStatus.id) ? (
                    <Button
                      text='Iniciar Torneo'
                      disabled={
                        tournament.TournamentType.minPlayers > players.length ||
                        loading
                      }
                      state='success'
                      onClick={this.startTournament}
                    />
                  ) : (
                    <Button
                      link={tournament.urlRounds}
                      state='success'
                      text='Ver rondas'
                    />
                  )}
                </div>

                {[1, 2].includes(tournament.TournamentStatus.id) && (
                  <p className='text-center'>
                    {ReactHtmlParser(
                      `Para iniciar este torneo de tipo "${
                        tournament.TournamentType.name
                      }" es necesario inscribir al menos <b>${
                        tournament.TournamentType.minPlayers
                      } jugadores</b>.`
                    )}
                  </p>
                )}
                <hr />
              </React.Fragment>

              {[1, 2, 3].includes(tournament.TournamentStatus.id) ? (
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 1
                      })}
                      onClick={() => {
                        this.toggle(1)
                      }}>
                      <i className='fa fa-list' /> Jugadores{' '}
                      {players.length > 0 ? `(${players.length})` : ''}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 2
                      })}
                      onClick={() => {
                        this.toggle(2)
                      }}>
                      Buscar jugador
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 3
                      })}
                      onClick={() => {
                        this.toggle(3)
                      }}>
                      Jugadores Recurrentes
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 4
                      })}
                      onClick={() => {
                        this.toggle(4)
                      }}>
                      Organizadores
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 5
                      })}
                      onClick={() => {
                        this.toggle(5)
                      }}>
                      <i className='fa fa-user-plus' /> Registrar nuevo Jugador
                    </NavLink>
                  </NavItem>
                </Nav>
              ) : (
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 1
                      })}
                      onClick={() => {
                        this.toggle(1)
                      }}>
                      <i className='fa fa-list' /> Jugadores{' '}
                      {players.length > 0 ? `(${players.length})` : ''}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 4
                      })}
                      onClick={() => {
                        this.toggle(4)
                      }}>
                      Organizadores
                    </NavLink>
                  </NavItem>
                </Nav>
              )}

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={1}>
                  {/* <TabEnrolled players={players} tournament={tournament} state={this.state} /> */}
                  <Card body>
                    <h3>
                      <i className='fa fa-users' /> Listado de Jugadores (
                      {players.length} / {tournament.maxPlayers})
                    </h3>
                    <p>
                      En esta sección podrás ver a todos los jugadores inscritos
                      en el Torneo.
                    </p>
                    <ul className='list-unstyled'>
                      {[2, 3, 4].includes(tournament.TournamentStatus.id) && (
                        <li>
                          <i className='fa fa-user-times text-danger btn' />
                          Permite eliminar un jugador del Torneo.
                        </li>
                      )}
                      {[1, 2].includes(tournament.TournamentStatus.id) && (
                        <React.Fragment>
                          <li>
                            <i className='fa fa-plus-circle text-info btn' />
                            Permite agregar rondas BYE al jugador.
                          </li>
                          <li>
                            <i className='fa fa-minus-circle text-danger btn' />
                            Permite eliminar una ronda BYE al jugador.
                          </li>
                        </React.Fragment>
                      )}
                      {[1, 2, 3].includes(tournament.TournamentStatus.id) && (
                        <li>
                          <i className='fa fa-refresh text-info btn'> </i>
                          Permite reemplazar a un jugador por otro.
                        </li>
                      )}
                      <li>
                        <i className={`fa fa-thumbs-up text-success btn`} />
                        Ha recibido correos del sistema.
                      </li>
                      <li>
                        <i className={`fa fa-thumbs-down text-danger btn`} />
                        NO ha recibido ningún correos del sistema.
                      </li>
                    </ul>
                    {players.length > 0 && (
                      <Table
                        className={`table-bordered table-hover ${
                          this.props.isMobile ? 'table-responsive' : ''
                        }`}>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            <th colSpan={!this.props.isMobile ? 2 : 1}>
                              Jugador
                            </th>
                            {!this.props.isMobile && <th>Email</th>}
                            {races.length > 0 && <th>Raza</th>}
                            {!this.props.isMobile && <th>Email</th>}
                            {!this.props.isMobile &&
                              players.some(xx => xx.clasified).length > 0 && (
                                <th>Clasificado</th>
                              )}
                            {!this.props.isMobile && someBye && (
                              <th>Rondas Bye</th>
                            )}
                            {!this.props.isMobile && someDrop && <th>Drop?</th>}
                            {[1, 2, 3, 4].includes(
                              tournament.TournamentStatus.id
                            ) && <th className='text-center'>Acciones</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {players.map((player, key) => {
                            const race = races.find(x => {
                              return x.id === player.raceId
                            })
                            return (
                              <tr key={key}>
                                <td className='text-center'>{key + 1}</td>
                                {!this.props.isMobile && (
                                  <td className='text-center'>
                                    <Avatar
                                      person={player}
                                      size={32}
                                      fontSize={12}
                                    />
                                  </td>
                                )}
                                {!this.props.isMobile ? (
                                  <>
                                    <td>
                                      {player.fullName}
                                      <br />
                                      {player.active === 2 &&
                                        '⛔️ Jugador Sancionado, no puede participar en torneos'}
                                    </td>
                                    <td className='text-center'>
                                      {player.code}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <b>{player.fullName}</b>
                                      <br />
                                      {player.code}
                                    </td>
                                  </>
                                )}

                                {races.length > 0 && (
                                  <td>
                                    <Select
                                      inputSize={12}
                                      disabled={tournament.statusId === 6}
                                      size='xs'
                                      // placeholder="Seleccionar la raza ocupada por el jugador"
                                      name='raceId'
                                      data-playerId={player.id}
                                      options={races}
                                      onChange={e =>
                                        this.onChangeRace(
                                          e,
                                          player.id,
                                          player.personId
                                        )
                                      }
                                      select={race ? race.id : 0}
                                    />
                                  </td>
                                )}
                                {!this.props.isMobile && (
                                  <td>
                                    {player.email}
                                    {!player.emailValid ? (
                                      <p>
                                        <i
                                          className={`fa fa-thumbs-down text-danger`}
                                        />
                                        <small>Email no ha sido validado</small>
                                      </p>
                                    ) : null}
                                  </td>
                                )}

                                {!this.props.isMobile &&
                                  players.some(xx => xx.clasified).length >
                                    0 && <td>{player.clasified}</td>}
                                {!this.props.isMobile && someBye && (
                                  <td className='text-center'>
                                    {player.byes
                                      ? t('byes', {
                                          count: player.byes
                                        })
                                      : ''}
                                  </td>
                                )}
                                {!this.props.isMobile && someDrop && (
                                  <td className='text-center'>
                                    {player.RoundDrop ? (
                                      <Link to={player.RoundDrop.urlView}>
                                        {player.RoundDrop.name}
                                      </Link>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                )}
                                {[1, 2, 3, 4].includes(
                                  tournament.TournamentStatus.id
                                ) && (
                                  <td>
                                    {/* Estados Inscripciones, Iniciado, Proceso se permite borrar jugadores */}
                                    {[1, 2, 3, 4].includes(
                                      tournament.TournamentStatus.id
                                    ) && (
                                      <span className='fa-stack fa-lg'>
                                        <i
                                          className={`fa ${
                                            !player.drop
                                              ? 'fa-user-times text-danger'
                                              : 'fa-user-o text-info'
                                          } btn`}
                                          onClick={() =>
                                            !player.drop &&
                                            this.setState({
                                              playerEdit: {
                                                ...player,
                                                action: 'delete'
                                              },
                                              modal: true
                                            })
                                          }
                                          id={`tooltipdelete${key}`}>
                                          <UncontrolledTooltip
                                            placement='top'
                                            target={`tooltipdelete${key}`}>
                                            {!player.drop
                                              ? 'Elimina un Jugador del Torneo'
                                              : 'Jugador Eliminado del torneo'}
                                          </UncontrolledTooltip>
                                        </i>
                                      </span>
                                    )}
                                    {/* Concede rondas bye */}
                                    {[1, 2].includes(
                                      tournament.TournamentStatus.id
                                    ) && (
                                      <React.Fragment>
                                        <span className='fa-stack fa-lg'>
                                          <i
                                            className='fa fa-plus-circle text-info btn'
                                            onClick={() =>
                                              this.setState({
                                                playerEdit: {
                                                  ...player,
                                                  bye: true,
                                                  action: 'bye'
                                                },
                                                modal: true
                                              })
                                            }
                                            id={`tooltipbye${key}`}>
                                            <UncontrolledTooltip
                                              placement='top'
                                              target={`tooltipbye${key}`}>
                                              Agrega una Ronda Bye
                                            </UncontrolledTooltip>
                                          </i>
                                        </span>
                                        {player.byes > 0 && (
                                          <span className='fa-stack fa-lg'>
                                            <i
                                              className='fa fa-minus-circle text-danger btn'
                                              onClick={() =>
                                                this.setState({
                                                  playerEdit: {
                                                    ...player,
                                                    bye: false,
                                                    action: 'bye'
                                                  },
                                                  modal: true
                                                })
                                              }
                                              id={`tooltipbyedel${key}`}>
                                              <UncontrolledTooltip
                                                placement='top'
                                                target={`tooltipbyedel${key}`}>
                                                Elimina una ronda bye al
                                                jugador.
                                              </UncontrolledTooltip>
                                            </i>
                                          </span>
                                        )}
                                      </React.Fragment>
                                    )}
                                    {[1, 2, 3].includes(
                                      tournament.TournamentStatus.id
                                    ) &&
                                      !player.drop && (
                                        <span className='fa-stack fa-lg'>
                                          <i
                                            className='fa fa-refresh text-info btn'
                                            aria-hidden='true'
                                            onClick={() =>
                                              this.setState({
                                                alertContent: '',
                                                playerEdit: {
                                                  ...player,
                                                  action: 'change'
                                                },
                                                addPlayer: {
                                                  ...this.state.addPlayer,
                                                  search: '',
                                                  results: [],
                                                  message: '',
                                                  personId: 0,
                                                  raceId: 0
                                                },
                                                modal: true
                                              })
                                            }
                                            id={`tooltipreplace${key}`}>
                                            <UncontrolledTooltip
                                              placement='top'
                                              target={`tooltipreplace${key}`}>
                                              Permite reemplazar a un jugador en
                                              el torneo.
                                            </UncontrolledTooltip>
                                          </i>
                                        </span>
                                      )}
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Card>
                </TabPane>
                <TabPane tabId={2}>
                  <Card body className='search__players'>
                    <div className='lead'>
                      <form onSubmit={this.handleAddPlayer}>
                        <h2>Buscador de Jugadores</h2>
                        <p>
                          En esta sección podrás buscar a un jugador e
                          inscribirlo en el Torneo. Puedes buscarlo por su
                          Nombre, Código Jugador o Email.
                        </p>
                        <Input
                          inputSize={12}
                          name='addPlayer'
                          placeholder='Buscar un jugador Nombre, Código o email.'
                          value={addPlayer.search}
                          onChange={this.onChange}
                          disabled={addPlayer.loading}
                        />
                        {addPlayer.results.length > 0 && (
                          <Fragment>
                            <Select
                              inputSize={12}
                              name='personId'
                              placeholder='Selecciona jugador'
                              options={addPlayer.results}
                              onChange={this.onChange}
                              select={addPlayer.personId}
                            />
                            {races.length > 0 && (
                              <Select
                                inputSize={12}
                                placeholder='Seleccionar la raza ocupada por el jugador'
                                name='raceId'
                                disabled={tournament.statusId === 6}
                                options={races}
                                onChange={this.onChange}
                                select={addPlayer.raceId}
                              />
                            )}
                            <Button
                              text='Inscribir al Torneo'
                              onClick={this.handleRegisterPlayer}
                              disabled={
                                !addPlayer.personId ||
                                (races.length > 0 && !addPlayer.raceId)
                              }
                            />
                          </Fragment>
                        )}
                        <p>{addPlayer.message}</p>
                      </form>
                    </div>
                  </Card>
                </TabPane>
                <TabPane tabId={3}>
                  <Card body>
                    <h2>Inscribir jugador recurrente</h2>
                    <p>
                      En esta sección podrás buscar a un jugador que haya
                      participado anteriormente en un torneo de tu tienda. Solo
                      debes buscarlo y agregarlo al Torneo.
                    </p>
                    <Input
                      inputSize={12}
                      name='locales'
                      placeholder='Buscar un jugador Nombre, Código o email.'
                      value={this.state.searchLocal}
                      onChange={this.filterLocals}
                    />
                    {filterPlayers.length > 0 && (
                      <Table
                        className={`table-bordered ${
                          this.props.isMobile ? 'table-responsive' : ''
                        }`}>
                        <thead>
                          <tr>
                            <th className='text-center'>#</th>
                            <th colSpan={2}>Jugador</th>
                            <th>Código</th>
                            <th>Estado</th>
                            <th className='text-center'>
                              <i className='fa fa-cogs' />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterPlayers.map((player, key) => {
                            return (
                              <tr key={key}>
                                <td className='text-center'>{key + 1}</td>
                                <td>
                                  <Avatar
                                    person={player}
                                    size={40}
                                    fontSize={20}
                                  />
                                </td>
                                <td>{player.fullName}</td>
                                <td>{player.code}</td>
                                <td>
                                  {player.active === 1
                                    ? '✅ Activo'
                                    : '⛔️ Sancionado'}
                                </td>
                                <td className='text-center'>
                                  <Button
                                    text={`Inscribir al Torneo`}
                                    icon='user'
                                    disabled={this.state.loadingLocal}
                                    onClick={() => this.newLocalPlayer(player)}
                                    style={{ margin: 'auto' }}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    )}
                    {!filterPlayers.length && (
                      <p className='text-center'>
                        De momento no tienes jugadores inscritos en ningún otro
                        torneo.
                      </p>
                    )}
                  </Card>
                </TabPane>
                <TabPane tabId={4}>
                  <Card body>
                    <h2>Asociar Organizadores</h2>
                    <p>
                      Los organizadores no podrán ser jugadores en el torneo.
                      Por lo tanto al asociarlo como jugador no podrá ser
                      organizador, y si lo asocias como Organizador no podrá ser
                      inscrito como Jugador.
                    </p>
                    <div>
                      <p>
                        El organizador podrá ayudar en la gestión torneo con las
                        siguientes herramientas:
                      </p>
                      <ul className='grid'>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Inscribir nuevos jugadores
                        </li>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Reportar resultados en rondas
                        </li>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Iniciar rondas
                        </li>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Eliminar rondas
                        </li>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Imprimir rondas
                        </li>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Terminar el torneo
                        </li>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Reportar torneo al ranking
                        </li>
                        <li>
                          {' '}
                          <span role='img' aria-label='public'>
                            ✅{' '}
                          </span>
                          Asociar a otros organizadores
                        </li>
                        <li>
                          <span role='img' aria-label='private'>
                            ⛔️{' '}
                          </span>{' '}
                          Eliminar el torneo
                        </li>
                      </ul>
                    </div>
                    <TournamentOrganizers />
                  </Card>
                </TabPane>
                <TabPane tabId={5}>
                  <Card body>
                    <h2>Registrar jugador</h2>
                    <p>
                      En esta sección podrás registrar a un jugador con sus
                      datos básicos para inscribirlo rápidamente al torneo
                    </p>
                    <SimpleUserRegister
                      {...this.props}
                      newLocalPlayer={this.newLocalPlayer}
                    />
                  </Card>
                </TabPane>
              </TabContent>
            </Jumbotron>
          )}

          <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>
              Gestión de Participantes
            </ModalHeader>
            <ModalBody>
              {playerEdit.action === 'delete' && (
                <React.Fragment>
                  <p>
                    ¿Confirmas eliminar al jugador <b>{playerEdit.fullName}</b>{' '}
                    del Torneo: {tournament.name}?
                  </p>
                  {tournament.TournamentStatus.id > 2 && (
                    <React.Fragment>
                      <p className='text-info'>
                        IMPORTANTE: Si el jugador está emparejado con algún
                        resultado pendiente se le otorgará un 2-0 a favor del
                        oponente y el jugador <b>{playerEdit.fullName}</b>{' '}
                        quedará <u>Dropeado del torneo</u>.
                      </p>
                      <p className='text-center text-danger'>
                        Esta acción no puede deshacerse
                      </p>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {playerEdit.action === 'change' && (
                <Jumbotron>
                  <h4>Reemplazo de Jugadores</h4>
                  <p>
                    Tipea el nombre, código o email del jugador para
                    reemplazarlo en el torneo por <b>{playerEdit.fullName}</b>
                  </p>
                  {alertContent.length > 0 && (
                    <Alert className={alertState}>{alertContent}</Alert>
                  )}
                  <div className='lead'>
                    <form onSubmit={this.handleReplacePlayer}>
                      <Input
                        inputSize={12}
                        name='replacePlayer'
                        placeholder='Buscar un jugador Nombre, Código o email.'
                        value={addPlayer.search}
                        onChange={this.onChange}
                        disabled={addPlayer.loading}
                      />
                      {addPlayer.results.length > 0 && (
                        <Fragment>
                          <Select
                            inputSize={12}
                            name='replacePersonId'
                            options={addPlayer.results}
                            onChange={this.onChange}
                            select={addPlayer.personId}
                          />
                          <div className='buttons'>
                            <Button
                              state='default'
                              onClick={this.toggleModal}
                              text='Cancelar'
                            />
                            <Button
                              state='success'
                              text='Cambiar Jugador'
                              onClick={this.updatePlayer}
                              disabled={!addPlayer.personId}
                            />
                          </div>
                        </Fragment>
                      )}
                      <p>{addPlayer.message}</p>
                    </form>
                  </div>
                </Jumbotron>
              )}
              {playerEdit.action === 'bye' && (
                <p>
                  ¿Confirmas {playerEdit.bye ? 'agregar' : 'quitar'} una ronda{' '}
                  <b>Libre</b> al jugador {playerEdit.fullName} [
                  {playerEdit.code}] en Torneo: {tournament.name}?
                </p>
              )}
            </ModalBody>
            {/* Solo mostramos el footer con los botones si estamos dando un bye o eliminando al jugador. */}
            {['delete', 'bye'].includes(playerEdit.action) && (
              <ModalFooter>
                <Button
                  state='default'
                  onClick={this.toggleModal}
                  text='Cancelar'
                />
                <Button
                  state={playerEdit.action === 'delete' ? 'danger' : 'success'}
                  onClick={this.updatePlayer}
                  text={
                    playerEdit.action === 'delete'
                      ? 'Eliminar Jugador'
                      : playerEdit.bye
                      ? 'Agregar Ronda Libre'
                      : 'Eliminar Ronda Libre'
                  }
                />
              </ModalFooter>
            )}
          </Modal>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentPlayersView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/
const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTournamentInfo,
      getTournamentPlayers,
      TournamentPlayerRegister,
      TournamentPlayerEdit,
      TournamentRoundStart,
      UserFind,
      getUser,
      getStorePlayers
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentPlayersView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Players' })`
  height: inherit;
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;
  .nav-tabs {
    a {
      margin: 0px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .search__players {
    min-height: 400px;
  }
`
