const Years = []
const init = 2019
const end = new Date().getFullYear()
for (var i = end; i >= init; i--) {
  Years.push({ id: i, name: i })
}
export const GameRaces = new Map([
  [1, 'Caballero'],
  [2, 'Guerrero'],
  [3, 'Sombra'],
  [4, 'Bestia'],
  [5, 'Dragón'],
  [6, 'Eterno'],
  [7, 'Faerie'],
  [8, 'Olímpico'],
  [9, 'Héroe'],
  [10, 'Titán'],
  [11, 'Desafiante'],
  [12, 'Defensor'],
  [13, 'Faraón'],
  [14, 'Sacerdote'],
  [15, 'Cazador'],
  [16, 'Licántropo'],
  [17, 'Vampiro'],
  [18, 'Chamán'],
  [19, 'Abominación'],
  [20, 'Bárbaro'],
  [21, 'Dios'],
  [22, 'Campeón'],
  [23, 'Criaturas'],
  [24, 'Kami'],
  [25, 'Xian'],
  [26, 'Tótem'],
  [27, 'Faerie / Goblin'],
  [28, 'Titán / Minotauro'],
  [29, 'Defensor / Druida'],
  [30, 'Sombra / Drácula'],
  [31, 'Triada Paladín'],
  [32, 'Triada Tenebris'],
  [33, 'Triada Desafiante']
])

export const games = new Map([
  [1, 'MyL Imperio'],
  [2, 'Humankind'],
  [3, 'MyL Online'],
  [4, 'MyL Primer Bloque'],
  [5, 'MyL Bloque Furia'],
  [6, 'MyL Primera Era'],
  [7, 'MyL Segundo Bloque'],
  [8, 'MyL Escuelas Elementales'],
  [9, 'Juego Casual'],
  [10, 'MyL Crónicas'],
  [11, 'MyL Furia Extendido']
])

export const razasEdiciones = {
  1: new Map([
    // Caballero
    [4, 'Espada Sagrada'],
    [6, 'El Reto']
  ]),
  2: new Map([
    // Guerrero
    [6, 'La Ira del Nahual']
  ]),
  3: new Map([
    // Sombra
    [4, 'Hijos de Daana']
  ]),
  4: new Map([
    // Bestia
    [6, 'La Ira del Nahual']
  ]),
  5: new Map([
    // Dragón
    [4, 'Espada Sagrada'],
    [6, 'El Reto']
  ]),
  6: new Map([
    // Eterno
    [4, 'Dominios de Ra']
  ]),
  7: new Map([
    // Faerie
    [4, 'Espada Sagrada'],
    [6, 'El Reto']
  ]),
  8: new Map([
    // Olímpico
    [4, 'Helénica']
  ]),
  9: new Map([
    // Héroe
    [4, 'Helénica']
  ]),
  10: new Map([
    // Titán
    [4, 'Helénica']
  ]),
  11: new Map([
    // Desafiante
    [4, 'Hijos de Daana']
  ]),
  12: new Map([
    // Defensor
    [4, 'Hijos de Daana']
  ]),
  13: new Map([
    // Faraón
    [4, 'Dominios de Ra']
  ]),
  14: new Map([
    // Sacerdote
    [4, 'Dominios de Ra']
  ]),
  15: new Map([
    // Cazador
    [6, 'Mundo Gótico']
  ]),
  16: new Map([
    // Licantropo
    [6, 'Mundo Gótico']
  ]),
  17: new Map([
    // Vampiro
    [6, 'Mundo Gótico']
  ]),
  18: new Map([
    // Chamán
    [6, 'La Ira del Nahual']
  ]),
  19: new Map([
    // Abominación
    [6, 'Ragnarok']
  ]),
  20: new Map([
    // Bárbaro
    [6, 'Ragnarok']
  ]),
  21: new Map([
    // Dios
    [6, 'Ragnarok']
  ]),
  22: new Map([
    // Campeón
    [6, 'Espíritu del Dragón']
  ]),
  23: new Map([
    // Criaturas
    [6, 'Espíritu del Dragón']
  ]),
  24: new Map([
    // Kami
    [6, 'Espíritu del Dragón']
  ]),
  25: new Map([
    // Xian
    [6, 'Espíritu del Dragón']
  ]),
  // 26: 'Tótem',
  27: new Map([
    // Faerie / Goblin
    [4, 'Espada Sagrada']
  ]),
  28: new Map([
    // Titán / Minotauro
    [4, 'Helénica']
  ]),
  29: new Map([
    // Defensor / Druida
    [4, 'Hijos de Daana']
  ]),
  30: new Map([
    // Sombra / Drácula
    [4, 'Drácula / Inferno']
  ])
  // 31: 'Triada Paladín',
  // 32: 'Triada Tenebris',
  // 33: 'Triada Desafiante'
}
